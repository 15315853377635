// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-base-js": () => import("./../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-controllers-js": () => import("./../src/pages/controllers.js" /* webpackChunkName: "component---src-pages-controllers-js" */),
  "component---src-pages-core-js": () => import("./../src/pages/core.js" /* webpackChunkName: "component---src-pages-core-js" */),
  "component---src-pages-details-js": () => import("./../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-frame-js": () => import("./../src/pages/frame.js" /* webpackChunkName: "component---src-pages-frame-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-head-js": () => import("./../src/pages/head.js" /* webpackChunkName: "component---src-pages-head-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-power-js": () => import("./../src/pages/power.js" /* webpackChunkName: "component---src-pages-power-js" */),
  "component---src-pages-proceed-js": () => import("./../src/pages/proceed.js" /* webpackChunkName: "component---src-pages-proceed-js" */)
}

